import identity from 'lodash-es/identity';
import pickBy from 'lodash-es/pickBy';

import { loginNameCookie, postOptions } from '../serviceUtilities';
import { getCookie, setCookie } from 'common/js/library/util';
import { emailSanitizer } from 'common/js/util/emailSanitizer';
import { getEcid } from 'common/js/library/get-ecid';
import {
    getLocalStorageItem,
    setLocalStorageItem,
    deleteLocalStorageItem
} from 'common/js/data/localstorage/localStorageService';

function expirationDate() {
    return new Date(Date.now() + 24 * 60 * 60 * 1000);
}

const updateTokenIdCookie = (data) => {
    const tokenId = data?.data?.tokenId;
    const isValid = data?.success === 'true';

    if (tokenId && isValid) {
        setCookie('tokenId', tokenId, 2147483647);
    }
};

export const createUser = async (values) => {
    try {
        const { email, password: authString, firstName, lastName, phone, storeNumber = '' } = values;

        let dataPackage = { email: emailSanitizer(email), authString, firstName, lastName, phone };

        if (storeNumber !== '') {
            dataPackage.storeNumber = storeNumber;
        }

        // Add the ECID to authenticate request
        const ecid = getEcid();
        if (ecid) {
            dataPackage.visitorID = ecid;
        }

        const options = postOptions(dataPackage);

        const response = await fetch('/bsro/services/account/create', options);

        if (!response.ok) {
            const errorData = await response.json();
            console.log('ERROR DATA: ', errorData);
            const errorString = `create account error. Response code ${errorData?.status}.\n\nError: ${errorData?.data?.error}`;
            throw new Error(errorString);
        }
        const data = await response.json();

        //console.log("create account data.data: ", data);

        if (data.success === 'true') {
            //console.log("successfully created account")
            setCookie(loginNameCookie, emailSanitizer(email), 2147483647);
            updateTokenIdCookie(data);
        }

        if (data) return data;
    } catch (err) {
        console.log("there's been an error in Create User: ", err);
    }
};

export const authenticateUser = async (values) => {
    try {
        // send 'authString' instead of 'password'
        const { email, password: authString } = values;

        let dataPackage = { email: emailSanitizer(email), authString };

        // Add the ECID to authenticate request
        const ecid = getEcid();
        if (ecid) {
            dataPackage.visitorID = ecid;
        }

        const options = postOptions(dataPackage);

        const response = await fetch('/bsro/services/account/authenticate', options);

        if (!response.ok) {
            const errorData = await response.json();
            console.log('ERROR DATA: ', errorData);
            const errorString = `authentication error: Response code ${errorData.status}.\n\nError: ${errorData.data.error}`;
            throw new Error(errorString);
        }
        const data = await response.json();

        if (data.status === 'InvalidLogin') {
            return data;
        } else {
            //console.log("sign-in response data.data: ", data.data);
            //const details = merge({}, data.data.account, { PROFILE: { isLoggedIn: data.data.loggedIn, tokenId: data.data.tokenId } } );
            //setAllUserDetails(details);
            setCookie(loginNameCookie, emailSanitizer(email), 2147483647);
            updateTokenIdCookie(data);
            return data;
        }
    } catch (err) {
        console.log("there's been an error in Authenticate User: ", err);
        return (
            err || {
                success: false
            }
        );
    }
};

export const checkUserToken = async (tokenId) => {
    try {
        if (tokenId == getCookie('tokenId')) {
            return getLocalStorageItem('userTokenData');
        }
        // get the cookie tokenId and the token, send to verify token validity
        const url = `/bsro/services/account/check`;
        const fetchOptions = {
            method: 'GET',
            credentials: 'include',
            headers: {
                tokenId: tokenId
            }
        };

        const response = await fetch(url, fetchOptions);

        if (response.ok) {
            const dataPack = await response.json();

            updateTokenIdCookie(dataPack);
            setLocalStorageItem('userTokenData', dataPack, expirationDate());

            return dataPack;
        } else {
            // TODO: figure out what conditions cause !response.ok and handle them gracefully
            // console.log()
        }
    } catch (err) {
        // do nothing
    }
};

export const getUserDetails = async (token) => {
    // using the tokenId and the token, request user details
    try {
        const url = `/bsro/services/account/get`;
        const fetchOptions = {
            method: 'GET',
            credentials: 'include',
            headers: {
                tokenId: token
            }
        };

        const response = await fetch(url, fetchOptions);

        if (!response.ok) {
            throw new Error('Network Response was not OK.');
        } else {
            const dataPack = await response.json();

            updateTokenIdCookie(dataPack);

            return dataPack;
        }
    } catch (err) {
        // do nothing
    }
};

export const updateUserLoginEmail = async (newEmail, oldEmail, password) => {
    try {
        const dataPackage = {
            oldEmail: emailSanitizer(oldEmail),
            newEmail: emailSanitizer(newEmail),
            oldAuthString: password,
            newAuthString: ''
        };

        const tokencookie = getCookie('tokenId');
        const options = postOptions(dataPackage, tokencookie);

        const response = await fetch('/bsro/services/account/update-access', options);

        if (!response.ok) {
            return {
                data: {
                    message: 'Service Unavailable'
                },
                success: 'false',
                status: 'BadRequest'
            };
        } else {
            const data = await response.json();

            updateTokenIdCookie(data);

            return data;
        }
    } catch (err) {
        // do nothing
    }
};

export const updatePassword = async (email, oldPassword, newPassword, temporaryTokenId) => {
    try {
        const dataPackage = {
            oldEmail: emailSanitizer(email),
            newEmail: '',
            oldAuthString: oldPassword,
            newAuthString: newPassword
        };

        const tokencookie = getCookie('tokenId');
        const tokenId = temporaryTokenId || tokencookie;
        const options = postOptions(dataPackage, tokenId);

        const response = await fetch('/bsro/services/account/update-access', options);

        if (!response.ok) {
            const errorData = await response.json();
            console.log('UPDATE PASSWORD ERROR: ', errorData);
            const errorString = `update password error: Response: ${errorData}`;
            throw new Error(errorString);
        }

        const data = await response.json();

        updateTokenIdCookie(data);

        return data;
    } catch (err) {
        console.log("there's been an error in Update Password API method: ", err);
    }
};

export const forgotPassword = async (email) => {
    try {
        const options = postOptions({ email: emailSanitizer(email) });

        const response = await fetch('/bsro/services/account/send-reset-password', options);

        if (!response.ok) {
            console.log('Forgot Password response not OK.');
        } else {
            const responseData = await response.json();

            updateTokenIdCookie(responseData);

            return responseData;
        }
    } catch (err) {
        console.log('there’s been an error in Forgot Password API method: ', err);
    }
};

export const resetUserPassword = async ({ email, authString, tokenId }) => {
    try {
        const options = postOptions({ email: emailSanitizer(email), authString, tokenId });
        const response = await fetch('/bsro/services/account/reset', options);

        if (!response.ok) {
            console.log('Reset Password Response not OK.');
        } else {
            const responseData = await response.json();

            updateTokenIdCookie(responseData);

            return responseData;
        }
    } catch (err) {
        console.log("there's been an error in reset password");
        return 'SYSTEM_ERROR';
    }
};

export const updateUserDetails = async (userDetails) => {
    try {
        console.log('update user details API: ', userDetails);

        const driverPackage = {
            DRIVER: [
                {
                    ID: userDetails.driverId,
                    FIRSTNAME: userDetails.firstName,
                    LASTNAME: userDetails.lastName,
                    EMAIL: emailSanitizer(userDetails.email),
                    PHONE: userDetails.phone,
                    ADDRESS: userDetails.address,
                    CITY: userDetails.city,
                    STATE: userDetails.state,
                    ZIP: userDetails.zip,
                    EMAIL_REMINDERS: userDetails.emailReminders || '0'
                }
            ]
        };

        const trimmedDriverPackage = pickBy(driverPackage, identity);

        console.log('trimmed Driver Package sent to API: ', trimmedDriverPackage);
        const url = `/bsro/services/account/update-section`;

        const options = postOptions(trimmedDriverPackage);

        const response = await fetch(url, options);

        if (!response.ok) {
            //throw new Error("Network Response was not OK");
            return {
                data: {
                    message: 'Service Unavailable.'
                },
                success: 'false',
                status: '500'
            };
        } else {
            const dataPack = await response.json();

            updateTokenIdCookie(dataPack);

            return dataPack;
        }
    } catch (err) {
        // do nothing
    }
};

export const verifyEmail = async (values) => {
    try {
        const dataPackage = {
            code: values.code,
            tokenId: values.tokenId,
            email: emailSanitizer(values.email)
        };

        const tokenCookie = getCookie('tokenId');
        const options = postOptions(dataPackage, tokenCookie);
        const response = await fetch('/bsro/services/account/verify-email', options);

        if (!response.ok) {
            return {
                data: {
                    message: 'Service Unavailable'
                },
                success: 'false',
                status: 'BadRequest'
            };
        } else {
            const data = await response.json();

            console.log('verify-email', data);

            updateTokenIdCookie(data);

            return data;
        }
    } catch (err) {
        // do nothing
    }
};

export const logoutUser = async (values) => {
    try {
        const { tokenId } = values;

        let dataPackage = { tokenId };

        const options = postOptions(dataPackage);

        const response = await fetch('/bsro/services/account/logout', options);

        if (!response.ok) {
            const errorData = await response.json();
            console.log('ERROR DATA: ', errorData);
            const errorString = `logout account error. Response code ${errorData?.status}.\n\nError: ${errorData?.data?.error}`;
            throw new Error(errorString);
        }

        const data = await response.json();

        if (data) return data;
    } catch (err) {
        console.log("there's been an error in Logout User: ", err);
    }
};

export const isUserLoggedIn = async () => {
    try {
        const token = getCookie('tokenId');

        if (!token) return false;

        const tokenCheck = await checkUserToken(token);

        if (tokenCheck.success === 'false') return false;

        return tokenCheck?.data?.loggedIn === 'true';
    } catch (err) {
        console.log('isLoggedIn checkUserToken', err);
        return false;
    }
};
